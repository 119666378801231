import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "items_to_hide", "navbar", "profile", "btns", "side_devider", "logout_btn", "pop_btn" ]

  connect() {
    // if (this.navbarTarget.style["width"] == "96px") {
    //   this.navbarTarget.style["width"] = "256px"
    // } else {
    //   this.navbarTarget.style["width"] = "96px"
    // }
    // this.items_to_hideTargets.forEach((item)=> {
    //   item.classList.toggle("hidden");
    // })
    // this.profileTarget.classList.toggle("navbar-profile-small")
    // this.btnsTarget.classList.toggle("navbar-btns-small")
    // this.side_deviderTarget.classList.toggle("navbar-side-divider-small")
    // this.logout_btnTarget.classList.toggle("navbar-logout-btn-small")
    // this.pop_btnTarget.classList.toggle("navbar-pop-btn-small")
    // debugger
    // let main = document.querySelector(".main")
    // if (main.style["margin-left"] == "96px") {
    //   main.style["margin-left"] = "256px"
    // } else {
    //   main.style["margin-left"] = "96px"
    // }
  }

  pop() {
    let main = document.querySelector(".main")
    if (this.navbarTarget.style["width"] == "96px") {
      this.navbarTarget.style["width"] = "256px"
      main.style["margin-left"] = "256px"
    } else {
      main.style["margin-left"] = "96px"
      this.navbarTarget.style["width"] = "96px"
    }
    this.items_to_hideTargets.forEach((item)=> {
      item.classList.toggle("hidden");
    })
    this.profileTarget.classList.toggle("navbar-profile-small")
    this.btnsTarget.classList.toggle("navbar-btns-small")
    this.side_deviderTarget.classList.toggle("navbar-side-divider-small")
    this.logout_btnTarget.classList.toggle("navbar-logout-btn-small")
    this.pop_btnTarget.classList.toggle("navbar-pop-btn-small")
    this.pop_btnTarget.classList.toggle("flip-180")
  }
}
