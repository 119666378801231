import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "categoriesDesc", "categoriesCode", "swapBtn" ]

  connect() {
    if (this.categoriesCodeTarget.selectedOptions[0].value) {
      var id = this.categoriesCodeTarget.selectedOptions[0].value
      this.categoriesDescTarget.value = id
    }
    if (this.categoriesDescTarget.selectedOptions[0].value) {
      var id = this.categoriesDescTarget.selectedOptions[0].value
      this.categoriesCodeTarget.value = id
    }
  }

  change_code() {
    var id = this.categoriesCodeTarget.selectedOptions[0].value
    this.categoriesDescTarget.value = id

  }

  change_desc() {
    var id = this.categoriesDescTarget.selectedOptions[0].value
    this.categoriesCodeTarget.value = id
  }

  swap() {
    this.categoriesDescTarget.classList.toggle('hidden')
    this.categoriesCodeTarget.classList.toggle('hidden')
  }
}
