// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "projectsBox", "firstArrow" ]

  connect() {
    this.projectsBoxTarget.classList.remove('hidden');
    this.firstArrowTarget.classList.add('rotate90')
    this.firstArrowTarget.classList.remove('rotate-90')
  }

  enlarge() {
    this.projectsBoxTarget.classList.toggle('hidden');
    if (this.firstArrowTarget.classList.contains('rotate90')) {
      this.firstArrowTarget.classList.add('rotate-90')
      this.firstArrowTarget.classList.remove('rotate90')
    } else {
      this.firstArrowTarget.classList.add('rotate90')
      this.firstArrowTarget.classList.remove('rotate-90')
    }
  }
}
