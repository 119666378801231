// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "productsBox", "thirdArrow" ]

  enlargeProducts() {
    this.productsBoxTarget.classList.toggle('hidden');
    if (this.thirdArrowTarget.classList.contains('rotate90')) {
      this.thirdArrowTarget.classList.add('rotate-90')
      this.thirdArrowTarget.classList.remove('rotate90')
    } else {
      this.thirdArrowTarget.classList.add('rotate90')
      this.thirdArrowTarget.classList.remove('rotate-90')
    }
  }
}
