import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "categoriesDesc", "categoriesCode", "swapBtn", "glCode", "glDesc", "company" ]

  connect() {
    if (this.categoriesCodeTarget.selectedOptions[0].value) {
      let id = this.categoriesCodeTarget.selectedOptions[0].value
      this.categoriesDescTarget.value = id
    }
    if (this.categoriesDescTarget.selectedOptions[0].value) {
      let id = this.categoriesDescTarget.selectedOptions[0].value
      this.categoriesCodeTarget.value = id
    }
    if (this.glDescTarget.selectedOptions[0]) {
      this.selectedGl = this.glDescTarget.selectedOptions[0].value
    }
    this.generals = this.glCodeTarget.cloneNode(true);
    this.glCodeTarget.innerHTML = "";
    let company = this.companyTarget.selectedOptions[0].label;
    if (company == "Prive") { company = "EA2" }
    let new_options = ['<option></option>'];
    Array.from(this.generals.options).forEach((obj) => {
      if (obj.parentElement.label == company) {
        new_options.push(`<option value=${obj.value}>${obj.text}</option>`)
      }
    })
    this.glCodeTarget.innerHTML = new_options;
    if (this.selectedGl) {
      this.glCodeTarget.value = this.selectedGl
    }
  }

  company_change() {
    this.glCodeTarget.innerHTML = "";
    let company = this.companyTarget.selectedOptions[0].label;
    if (company == "Prive") { company = "EA2" }
    let new_options = ['<option></option>'];
    Array.from(this.generals.options).forEach((obj) => {
      if (obj.parentElement.label == company) {
        new_options.push(`<option value=${obj.value}>${obj.text}</option>`)
      }
    })
    this.glCodeTarget.innerHTML = new_options;
  }

  change_code() {
    let id = this.categoriesCodeTarget.selectedOptions[0].value
    this.categoriesDescTarget.value = id

  }

  change_desc() {
    let id = this.categoriesDescTarget.selectedOptions[0].value
    this.categoriesCodeTarget.value = id
  }

  change_code_gl() {
    let id = this.glCodeTarget.selectedOptions[0].value
    this.glDescTarget.value = id

  }

  change_desc_gl() {
    let id = this.glDescTarget.selectedOptions[0].value
    this.glCodeTarget.value = id
  }

  swap() {
    this.categoriesDescTarget.classList.toggle('hidden')
    this.categoriesCodeTarget.classList.toggle('hidden')
    this.glDescTarget.classList.toggle('hidden')
    this.glCodeTarget.classList.toggle('hidden')
  }
}
