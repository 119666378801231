import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["project", "location", "product"]

  initialize() {
    this.loc = this.locationTarget.cloneNode(true);
    this.prod = this.productTarget.cloneNode(true);
    this.selectedLocation = this.locationTarget.selectedOptions[0]
    this.selectedProduct = this.productTarget.selectedOptions[0]
      // Same as filterProject
    this.locationTarget.innerHTML = "";
    let project = this.projectTarget.selectedOptions[0].label;
    let new_options = ['<option value=""></option>']
    Array.from(this.loc.options).forEach((obj) => {
      if (obj.parentElement.label == project) {
        new_options.push(`<option value=${obj.value}>${obj.text}</option>`)
      }
    })
    this.locationTarget.innerHTML = new_options;
    Array.from(this.locationTarget.options).forEach((option) => {
    	if (option.value == this.selectedLocation.value) {
        option.selected = true
      }
    });
      // Same as filterLocation
    this.productTarget.innerHTML = "";
    let location = this.locationTarget.selectedOptions[0].label;
    new_options = ['<option value=""></option>'];
    Array.from(this.prod.options).forEach((obj) => {
      if (obj.parentElement.label == location) {
        new_options.push(`<option value=${obj.value}>${obj.text}</option>`)
      }
    })
    this.productTarget.innerHTML = new_options;
    Array.from(this.productTarget.options).forEach((option) => {
      if (option.value == this.selectedProduct.value) {
        option.selected = true
      }
    });
  }

  filterProject() {
    this.locationTarget.innerHTML = "";
    this.productTarget.innerHTML = "";
    let project = this.projectTarget.selectedOptions[0].label;
    let new_options = ['<option value=""></option>']
    Array.from(this.loc.options).forEach((obj) => {
      if (obj.parentElement.label == project) {
        new_options.push(`<option value=${obj.value}>${obj.text}</option>`)
      }
    })
    this.locationTarget.innerHTML = new_options;
    let location = this.locationTarget.selectedOptions[0].label;
    new_options = ['<option value=""></option>']
    Array.from(this.prod.options).forEach((obj) => {
      if (obj.parentElement.label == location) {
        new_options.push(`<option value=${obj.value}>${obj.text}</option>`)
      }
    })
    this.productTarget.innerHTML = new_options;
  }

  filterLocation() {
    this.productTarget.innerHTML = "";
    let location = this.locationTarget.selectedOptions[0].label;
    let new_options = ['<option value=""></option>']
    Array.from(this.prod.options).forEach((obj) => {
      if (obj.parentElement.label == location) {
        new_options.push(`<option value=${obj.value}>${obj.text}</option>`)
      }
    })
    this.productTarget.innerHTML = new_options
  }
}
