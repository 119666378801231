// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "vatCost", "vatPerc", 'totalCost', 'zeroVatCost' ]

  fill_cost() {
    if (this.vatPercTarget.value) {
      var cost_ex_vat = ((parseFloat(this.totalCostTarget.value) - parseFloat(this.zeroVatCostTarget.value || 0)) / (parseFloat(this.vatPercTarget.value)/100 + 1 ));
      this.vatCostTarget.value = (Math.round(((parseFloat(this.totalCostTarget.value) - parseFloat(this.zeroVatCostTarget.value || 0)) - cost_ex_vat) * 100) / 100);
    }
  }

  fill_perc() {
    if (this.vatCostTarget.value) {
      var cost_ex_vat = ((parseFloat(this.totalCostTarget.value) - parseFloat(this.zeroVatCostTarget.value || 0)) - parseFloat(this.vatCostTarget.value));
      this.vatPercTarget.value = Math.round((parseFloat(this.vatCostTarget.value) / cost_ex_vat * 100) * 100) / 100;
    }
  }
}
