
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'costPaymentMethod', 'digitsForm', 'digitsFormField' ]

  connect(){
    if (this.costPaymentMethodTarget.value == 'Kaart') {
      this.digitsFormTarget.classList.remove('hidden')
    }
  }

  change() {
    if (this.costPaymentMethodTarget.value == 'Kaart') {
      this.digitsFormTarget.classList.remove('hidden')
    } else {
      this.digitsFormTarget.classList.add('hidden')
      this.digitsFormFieldTarget.value = '';
    }
  }
}
