import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["project", "location", "product", "generalLedger", "company", "addProduct", 'carSelect', 'carSelector', 'truckIcon', 'distanceField']

  initialize() {
    this.loc = this.locationTarget.cloneNode(true);
    this.prod = this.productTarget.cloneNode(true);
    if (this.hasGeneralLedgerTarget) {
      this.generals = this.generalLedgerTarget.cloneNode(true);
    }
    // Same as filterProject
    if (this.locationTarget.selectedOptions[0]) {
      this.selectedLocationId = this.locationTarget.selectedOptions[0].value
    }
    this.locationTarget.innerHTML = "";
    let project = this.projectTarget.selectedOptions[0].label;
    let new_options = ['<option value=""></option>']
    Array.from(this.loc.options).forEach((obj) => {
      if (obj.parentElement.label == project) {
        new_options.push(`<option value=${obj.value}>${obj.text}</option>`)
      }
    })
    this.locationTarget.innerHTML = new_options;
    if (this.selectedLocationId) {
      this.locationTarget.value = this.selectedLocationId
    }
    // Same as filterLocation
    this.productTarget.innerHTML = "";
    let location = this.locationTarget.selectedOptions[0].label;
    new_options = ['<option value=""></option>'];
    Array.from(this.prod.options).forEach((obj) => {
      if (obj.parentElement.label == location) {
        new_options.push(`<option value=${obj.value}>${obj.text}</option>`)
      }
    })
    this.productTarget.innerHTML = new_options;
    // Same as filterGeneralLedger
    if (this.generalLedgerTarget.selectedOptions[0]) {
      this.selectedGl = this.generalLedgerTarget.selectedOptions[0].value
    }
    if (this.hasGeneralLedgerTarget) {
      this.generalLedgerTarget.innerHTML = "";
      let company = this.companyTarget.selectedOptions[0].label;
      if (company == "Prive") { company = "EA2" }
      new_options = ['<option></option>'];
      Array.from(this.generals.options).forEach((obj) => {
        if (obj.parentElement.label == company) {
          new_options.push(`<option value=${obj.value}>${obj.text}</option>`)
        }
      })
      this.generalLedgerTarget.innerHTML = new_options;
      if (this.selectedGl) {
        this.generalLedgerTarget.value = this.selectedGl
      }
    }

    // Check if it's nil to show the 'Add product' button
    if (this.locationTarget.selectedOptions[0].label == "") {
      this.addProductTarget.classList.add('hidden')
    } else {
      this.addProductTarget.classList.remove('hidden')
    }
  }

  connect() {
    if (this.hasCarSelectorTarget) {
      if (this.carSelectorTarget.options[0].selected == false) {
        this.carSelectTarget.classList.remove('hidden')
        this.truckIconTarget.classList.add('navbar-active');
      }
    }
  }


  filterProject() {
    this.locationTarget.innerHTML = "";
    this.productTarget.innerHTML = "";
    let project = this.projectTarget.selectedOptions[0].label;
    let new_options = ['<option value=""></option>']
    Array.from(this.loc.options).forEach((obj) => {
      if (obj.parentElement.label == project) {
        new_options.push(`<option value=${obj.value}>${obj.text}</option>`)
      }
    })
    this.locationTarget.innerHTML = new_options;
    let location = this.locationTarget.selectedOptions[0].label;
    new_options = ['<option value=""></option>']
    Array.from(this.prod.options).forEach((obj) => {
      if (obj.parentElement.label == location) {
        new_options.push(`<option value=${obj.value}>${obj.text}</option>`)
      }
    })
    this.productTarget.innerHTML = new_options;
    this.addProductTarget.classList.add('hidden');
  }

  filterLocation() {
    this.productTarget.innerHTML = "";
    let location = this.locationTarget.selectedOptions[0].label;
    let new_options = ['<option value=""></option>']
    Array.from(this.prod.options).forEach((obj) => {
      if (obj.parentElement.label == location) {
        new_options.push(`<option value=${obj.value}>${obj.text}</option>`)
      }
    })
    this.productTarget.innerHTML = new_options

    // Check if it's nil to show the 'Add product' button
    if (this.locationTarget.selectedOptions[0].label == "") {
      this.addProductTarget.classList.add('hidden')
    } else {
      this.addProductTarget.classList.remove('hidden')
    }
  }

  filterGeneralLedger() {
    this.generalLedgerTarget.innerHTML = "";
    let company = this.companyTarget.selectedOptions[0].label;
    if (company == "Prive") { company = "EA2" }
    let new_options = ['<option></option>'];
    Array.from(this.generals.options).forEach((obj) => {
      if (obj.parentElement.label == company) {
        new_options.push(`<option value=${obj.value}>${obj.text}</option>`)
      }
    })
    this.generalLedgerTarget.innerHTML = new_options;
  }

  car() {
    this.truckIconTarget.classList.toggle('navbar-active');
    if (this.carSelectTarget.classList.contains('hidden')) {
      this.carSelectTarget.classList.remove('hidden')
    } else {
      this.carSelectTarget.classList.add('hidden')
      this.carSelectorTarget.options[0].selected = true
      this.distanceFieldTarget.value = 0;
    }
  }
}
