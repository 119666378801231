// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "company", "doc_type" ]

  connect() {
    if (!document.getElementById('myModalLabel')) {
      let code = "";
      code += this.companyTarget.selectedOptions[0].label.split("")[0];
      code += this.doc_typeTarget.value.split("")[0];
      document.querySelectorAll('.new-payable-codes').forEach(element => {
        if (element.innerText.split(": ")[1].split("").slice(2, 4).join("") == code) {
          element.classList.remove("hidden")
        } else {
          element.classList.add("hidden")
        }
      });
    }
  }

  change() {
    let code = "";
    code += this.companyTarget.selectedOptions[0].label.split("")[0];
    code += this.doc_typeTarget.value.split("")[0];
    document.querySelectorAll('.new-payable-codes').forEach(element => {
      if (element.innerText.split(": ")[1].split("").slice(2, 4).join("") == code) {
        element.classList.remove("hidden")
      } else {
        element.classList.add("hidden")
      }
    });
  }
}
